import React from "react"
import styled from "styled-components"
import { colors } from "../style/colors"

const Container = styled.div`
  margin-bottom: 50px;
`
const Number = styled.h1`
  color: ${colors.yellow};
  text-transform: uppercase;
  width: 100%;
  white-space: pre-wrap;
  font-size: 15vw;
  line-height: 90%;
    font-variation-settings: "wght" 900, "PROP" 1, "XPRN" 1, "CASL" 1;
  text-align: left;
`
const Copy = styled.p`
  margin-right: 50px;
  color: white;
  font-size: 18px;

  @media (max-width: 768px) {
    margin-right: 10px;
  }

  strong {
    font-size: 25px;
    font-variation-settings: "wght" 650, "PROP" 1, "XPRN" 1, "CASL" 1;
  }
`
const ShowMore = styled.button`
  background: ${colors.yellow};
  color: black;
  font-family: "Recursive";
  font-variation-settings: "wght" 700, "PROP" 1, "XPRN" 1, "CASL" 1;
  text-transform: uppercase;
  border: 1px solid ${colors.yellow};
  font-size: 24px;
  padding: 26px 15px;
  transition: 0.3s ease;

  cursor: pointer;

  :hover {
    font-variation-settings: "wght" 900, "PROP" 1, "XPRN" 1, "CASL" 1;
  }

  :focus {
    outline: none;
  }

  @media (max-width: 800px) {
    padding: 16px 15px;
  }
`

const SampleItem = props => (
  <Container>
    <Number>{props.number}.</Number>
    <Copy dangerouslySetInnerHTML={{ __html: props.copy }} />
    <ShowMore>Learn More</ShowMore>
  </Container>
)

export default SampleItem
