import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import BackgroundTitle from "../components/background-title"
import SampleItem from "../components/sample-item"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { colors } from "../style/colors"
import { graphql } from "gatsby"
import SEO from "../components/seo"

const Container = styled.div`
  background-color: black;
  width: 100vw;
  overflow: hidden;
  position: relative;
`
const Grid = styled.div`
  padding: 50px 90px 70px;
  display: flex;
  flex-direction: row;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    /* padding: 250px 30px 70px; */
    padding: 10px 30px 70px;
  }
`
const TextContainer = styled.div`
  max-width: 50%;
  color: white;
  margin-right: 50px;

  @media (max-width: 900px) {
    max-width: 50%;
  }

  @media (max-width: 800px) {
    margin: 50px 0 0 0;
    max-width: none;
  }

  p {
    font-size: 26px;
    line-height: 120%;
    font-variation-settings: "wght" 450, "PROP" 1, "XPRN" 1;
  }
`
const SampleContainer = styled.div`
  padding: 0 90px;
  display: grid;
  grid-template-columns: auto auto auto;

  @media (max-width: 800px) {
    grid-template-columns: auto;
    padding: 0 40px;
  }
`
const WorksContainer = styled.div`
  background-color: white;
  padding: 100px 0 0 0;
`
const WorkGrid = styled.div`
  padding: 55% 50px 0 20px;
  display: grid;
  grid-template-columns: 30% auto;

  @media (max-width: 800px) {
    grid-template-columns: 10% auto;
    padding: 55% 20px 0 20px;
  }
`
const Number = styled.h1`
  color: ${colors.blue};
  text-transform: uppercase;
  font-size: 15vw;
  line-height: 90%;
    font-variation-settings: "wght" 900, "PROP" 1, "XPRN" 1, "CASL" 1;
  text-align: center;
  white-space: nowrap;

  @media (max-width: 800px) {
    font-size: 10vw;
  }
`
const WorkContent = styled.div`
  margin-bottom: 50px;
`
const ImageContainer = styled.div`
  width: 100%;
  height: 400px;
  background-color: black;
  margin-bottom: 50px;
  overflow: hidden;
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Description = styled.p`
  font-size: 22px;
  font-variation-settings: "wght" 460, "PROP" 1, "XPRN" 0;
  line-height: 150%;

  @media (max-width: 600px) {
    font-size: 22px;
    line-height: 150%;
  }

  p {
    hyphens: auto;
    -moz-hyphens: auto;
    word-break: break-word;
  }
`

const Button = styled.button`
  background: black;
  color: white;
  justify-self: center;
  font-family: "Recursive";
  font-variation-settings: "wght" 500, "PROP" 1, "XPRN" 1;
  text-transform: uppercase;
  border: none;
  font-size: 30px;
  padding: 26px 15px;
  cursor: pointer;
  transition: 0.3s ease;

  :hover {
    font-variation-settings: "wght" 900, "PROP" 1, "XPRN" 1;
  }

  :focus {
    outline: none;
  }

  @media (max-width: 600px) {
    font-size: 20px;
    padding: 20px 15px;
  }
`
class WorkPage extends React.PureComponent {
  state = {}
  render() {
    const data = this.props.data.allWordpressPage.edges[0].node
    return (
      <Container>
        <Layout>
          <SEO title="My Work" />
          <BackgroundTitle relative left visible yellow title="Work" />
          <Grid>
            <TextContainer
              dangerouslySetInnerHTML={{ __html: data.content }}
            ></TextContainer>
          </Grid>
          <SampleContainer>
            <AnchorLink
              offset="50"
              style={{ textDecoration: "none" }}
              href="#1"
            >
              <SampleItem number="1" copy={data.acf.service_copy_1} />
            </AnchorLink>
            <AnchorLink
              offset="50"
              style={{ textDecoration: "none" }}
              href="#2"
            >
              <SampleItem number="2" copy={data.acf.service_copy_2} />
            </AnchorLink>
            <AnchorLink
              offset="50"
              style={{ textDecoration: "none" }}
              href="#3"
            >
              <SampleItem number="3" copy={data.acf.service_copy_3} />
            </AnchorLink>
          </SampleContainer>
          <WorksContainer>
            <BackgroundTitle visible left blue title="Sample Works" />
            <WorkGrid>
              <Number id="1">1.</Number>
              <WorkContent>
                <Description
                  dangerouslySetInnerHTML={{
                    __html: data.acf.sample_work_copy_1,
                  }}
                />
                <Button><a target="_blank" style={{ color: 'inherit', textDecoration: 'none' }} href={data.acf.sample_work_pdf_1.url.source_url}>View Sample PDF</a></Button>

              </WorkContent>
              <Number id="2">2.</Number>
              <WorkContent>
                <Description
                  dangerouslySetInnerHTML={{
                    __html: data.acf.sample_work_copy_2,
                  }}
                />
                <Button><a target="_blank" style={{ color: 'inherit', textDecoration: 'none' }} href={data.acf.sample_work_2_website}>Visit Website</a></Button>

              </WorkContent>
              <Number id="3">3.</Number>
              <WorkContent>
                <Description
                  dangerouslySetInnerHTML={{
                    __html: data.acf.sample_work_copy_3,
                  }}
                />
                <Button><a target="_blank" style={{ color: 'inherit', textDecoration: 'none' }} href={data.acf.sample_work_pdf_3.url.source_url}>View Sample PDF</a></Button>
              </WorkContent>
            </WorkGrid>
          </WorksContainer>
        </Layout>
      </Container>
    )
  }
}

export default WorkPage

export const workQuery = graphql`
  query($homepage: String = "Work") {
    allWordpressPage(filter: { title: { eq: $homepage } }) {
      edges {
        node {
          title
          content
          acf {
            service_copy_1
            service_copy_2
            service_copy_3
            sample_work_pdf_1 {
            url {
              source_url
            }
          }
            sample_work_2_website
            sample_work_pdf_3 {
            url {
              source_url
            }
          }
            sample_work_copy_1
            sample_work_copy_2
            sample_work_copy_3
          }
        }
      }
    }
  }
`
